var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "fill-height justify-center pa-0",
      staticStyle: { background: "#122338", width: "450px" },
      attrs: { id: "login", tag: "section" },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "px-5 my-4",
          attrs: { dark: "", width: "450", color: "#122338" },
        },
        [
          _vm.alert.status
            ? _c(
                "v-alert",
                {
                  staticClass: "mb-3 mt-0",
                  attrs: { type: "error", dismissible: "" },
                },
                [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-img", {
                    staticClass: "mt-8",
                    attrs: {
                      center: "",
                      "max-width": "174",
                      src: require("@/assets/logo.svg"),
                    },
                  }),
                  _c("v-card-text", { staticClass: "text-center mb-4" }, [
                    _c("strong", [_vm._v("Login")]),
                    _vm._v(" to your Account "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.loading
            ? _c(
                "v-row",
                { staticClass: "fill-height", attrs: { justify: "center" } },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 50, color: "primary", indeterminate: "" },
                  }),
                ],
                1
              )
            : _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.login.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center pb-2" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          name: "username",
                          color: "secondary",
                          label: "Email...",
                          "prepend-inner-icon": "mdi-email",
                          required: "",
                          counter: 70,
                          maxlength: "70",
                          rules: _vm.rules.username,
                        },
                        model: {
                          value: _vm.credentials.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.credentials, "username", $$v)
                          },
                          expression: "credentials.username",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "mb-8",
                        attrs: {
                          name: "password",
                          "append-icon": _vm.passwordShow
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          color: "secondary",
                          label: "Password...",
                          "prepend-inner-icon": "mdi-lock-outline",
                          required: "",
                          counter: 20,
                          maxlength: "20",
                          rules: _vm.rules.password,
                          type: _vm.passwordShow ? "text" : "password",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.passwordShow = !_vm.passwordShow
                          },
                        },
                        model: {
                          value: _vm.credentials.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.credentials, "password", $$v)
                          },
                          expression: "credentials.password",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "login-button",
                            type: "submit",
                            color: "#F49700",
                            depressed: "",
                            disabled: !_vm.valid,
                          },
                        },
                        [_vm._v(" Login ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }